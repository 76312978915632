import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Spacer, Grey } from '@components/utils';
import { useNavigateState } from '@components/navigate';
import Link from '@components/link';

export default ({ project, visible }) => {
  const { active } = useNavigateState();

  const visibleAnimation = useSpring({
    from: {
      opacity: 0,
      translate: 50,
    },
    to: {
      opacity: visible ? 1 : 0,
      translate: visible ? 0 : 50,
    },
    duration: 500,
  });

  const initialAnimation = useSpring({
    from: {
      opacity: 0,
      translate: -10,
    },
    to: {
      opacity: active ? 1 : 0,
      translate: active ? 0 : -10,
    },
    duration: 300,
  });

  const { translate: visibleTranslate, ...restVisibleAnimation } = visibleAnimation;
  const { translate: initialTranslate, ...restInitialAnimation } = initialAnimation;

  return (
    <>
      <animated.div
        style={{ ...restInitialAnimation, transform: initialTranslate.interpolate(x => `translate3d(0,${x}px,0)`) }}
      >
        <animated.div
          style={{ ...restVisibleAnimation, transform: visibleTranslate.interpolate(x => `translate3d(0,${x}px,0)`) }}
        >
          <Link url={`/${project.slug}/`}>{project.title}</Link>
          <div>
            <Grey>{project.type}</Grey>
          </div>
        </animated.div>
      </animated.div>
    </>
  );
};
