import React from 'react';
import PortfolioItem from '@components/portfolio-item';
import Container from '@components/container';
import { Spacer } from '@components/utils';

export default ({ projects }) => {
  return (
    <main>
      <Container>
        {projects.map((project, index) => (
          <React.Fragment key={project.index}>
            <PortfolioItem
              key={project.index}
              project={project}
              isFirst={index === 0}
              isLast={index === projects.length - 1}
            />
          </React.Fragment>
        ))}
      </Container>
    </main>
  );
};
