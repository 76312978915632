import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/layout';
import Hero from '@components/hero';
import Portfolio from '@components/portfolio';
import Footer from '@components/footer';
import { aboutMeSelector, linksSelector } from '@selectors/about';
import { projectSelector } from '@selectors/project';
import { metaSelector } from '@selectors/meta';
import FadeTransition from '@components/fade-transition';
import Nav from '@components/nav';
import NavSticky from '@components/nav-sticky';

export default ({ data, pageContext }) => {
  const { slug } = pageContext;
  const about = aboutMeSelector(data);
  const links = linksSelector(data);
  const projects = projectSelector(data);
  const { title, description, keywords, shareImage } = metaSelector(data);

  return (
    <Layout title={`Work | ${title}`} description={description} keywords={keywords} shareImage={shareImage}>
      <div>
        <Nav about={about} />
        <NavSticky about={about} />
      </div>
      <FadeTransition>
        <Hero about={about} />
      </FadeTransition>
      <Portfolio projects={projects} slug={slug} />
      <FadeTransition>
        <Footer links={links} about={about} />
      </FadeTransition>
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulMeta {
      edges {
        node {
          title
          keywords {
            id
            keywords
          }
          description {
            id
            description
          }
          shareImage {
            fixed(width: 628) {
              src
            }
          }
        }
      }
    }
    allContentfulServices {
      edges {
        node {
          name
        }
      }
    }
    allContentfulAboutMeLinks {
      edges {
        node {
          name
          url
        }
      }
    }
    allContentfulAboutMe(limit: 1) {
      edges {
        node {
          headline
          subHeadline
          contact
          email
          phone
          portrait {
            file {
              url
              fileName
            }
          }
          childContentfulAboutMeRecentPartnersTextNode {
            internal {
              content
            }
          }
          childContentfulAboutMeBioRichTextNode {
            json
          }
        }
      }
    }
    allContentfulProject(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          title
          type
          position
          description {
            id
            description
          }
          metadata {
            id
            metadata
          }
          credits {
            id
            credits
          }
          hero {
            fixed(width: 2340) {
              src
            }
          }
          expandedHero {
            fixed(width: 2340) {
              src
            }
          }
          details {
            __typename
            ... on ContentfulSingleImage {
              caption
              width
              image {
                file {
                  url
                }
              }
            }
            ... on ContentfulMultipleImages {
              images {
                file {
                  url
                }
              }
            }
            ... on ContentfulQuote {
              quote {
                quote
              }
            }
            ... on ContentfulBreakdown {
              title
              body {
                body
              }
              width
              align
              image {
                file {
                  url
                }
              }
              videoThumbnail {
                file {
                  url
                }
              }
            }
            ... on ContentfulVideo {
              caption
              width
              video {
                id
                file {
                  url
                  fileName
                  contentType
                }
              }
              videoThumbnail {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
