import React, { useState } from 'react';
import PortfolioHero from '@components/portfolio-hero';
import PortfolioSidebar from '@components/portfolio-sidebar';
import { Wrap, Sidebar, Content } from '@components/content';
import { Spacer } from '@components/utils';

export default ({ project, isFirst, isLast }) => {
  const [visible, setVisible] = useState(isFirst);

  return (
    <article>
      <Spacer size={13.4} md={9.4} sm={7} />
      <Wrap>
        <Sidebar>
          <PortfolioSidebar project={project} visible={visible} />
        </Sidebar>
        <Content>
          <Spacer size={0.6} />
          <PortfolioHero project={project} lazyLoad={true} setVisible={setVisible} visible={visible} />
        </Content>
      </Wrap>
      {isLast && <Spacer size={14} sm={8} />}
    </article>
  );
};
